<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h3 class="card-title text-center" v-if="$route.params.id">{{$t('edit_customer')}}</h3>
        <h3 class="card-title text-center" v-else>{{$t('new_customer')}}</h3>
        <hr>
        <div class="row mt-3">
          <div class="col-md-8 offset-md-2">
            <!-- <div class="form-group row">
              <div class="col-sm-12">
                <div class="mb-2 img">
                  <img width="100" :src="formData.file_name" alt="avatar">
                </div>
                <input ref="attachment" @change="onFileSelected" accept="image/*" type="file">
              </div>
            </div> -->
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">{{$t('full_name')}}
              </label>
              <div class="col-sm-8">
                <small class="text-danger" v-if="errors.full_name"> {{$t('required')}} </small>
                <input v-model="formData.full_name" type="text" class="form-control" :placeholder="$t('name')">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">{{$t('phone_number')}}</label>
              <div class="col-sm-8">
                <small class="text-danger" v-if="errors.phone"> {{$t('required')}} </small>
                <input v-model="formData.phone" type="text" class="form-control" :placeholder="$t('phone')">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">{{$t('full_address')}}</label>
              <div class="col-sm-8">
                <small class="text-danger" v-if="errors.address"> {{$t('required')}} </small>
                <textarea v-model="formData.address" rows="5" class="form-control" :placeholder="$t('address')">
                  </textarea>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">{{$t('advance')}}</label>
              <div class="col-sm-8">
                <small class="text-danger" v-if="errors.advance"> {{$t('required')}} </small>
                <input v-model="formData.advance" type="number" class="form-control" :placeholder="$t('price')">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">{{$t('bottle_price')}}</label>
              <div class="col-sm-8">
                <small class="text-danger" v-if="errors.bottle_price"> {{$t('required')}} </small>
                <input v-model="formData.bottle_price" type="number" class="form-control" :placeholder="$t('price')">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">{{$t('total_bottle_take')}}</label>
              <div class="col-sm-8">
                <small class="text-danger" v-if="errors.total_bottles"> {{$t('required')}} </small>
                <input v-model="formData.total_bottles" type="number" class="form-control" :placeholder="$t('bottle')">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">{{$t('vehicle')}}</label>
              <div class="col-sm-8">
                <small class="text-danger" v-if="errors.user_id"> {{$t('required')}} </small>
                <select v-model="formData.user_id">
                  <option v-for="v in vehicles" :key="v.id" :value="v.id">
                    {{v.username}}
                  </option>
                </select>
              </div>
            </div>
            <br>
            <button @click="save()" class="btn btn-success btn-block" v-if="$route.params.id">{{$t('update')}}</button>
            <button @click="save()" class="btn btn-success btn-block" v-else>{{$t('submit')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        vehicles: [],
        formData: {
          file_name: '/images/avatar.png',
          image: null,
          full_name: null,
          bottle_price: 0,
          total_bottles: 0,
          advance: 0,
          phone: null,
          address: null,
          user_id: null,
        },
        errors: {}
      }
    },
    mounted() {
      if (this.$route.params.id) {
        this.getData();
      }
      this.getVehicles();
    },
    methods: {
      async getVehicles() {
        let params = {
          role: 'vehicle',
          page: 1,
          limit: 'all',
          sorts: [{
            column: "username",
            type: "asc"
          }]
        }
        let res = await axios.get('users', {
          params
        });
        if (res) {
          this.vehicles = res.data.data;
        }
      },
      async getData() {
        let res = await axios.get('customers/' + this.$route.params.id);
        let formData = res.data.data;
        formData['file_name'] = formData.image;
        formData['image'] = null
        this.formData = formData;
      },
      async onFileSelected(event) {
        let file = event.target.files[0];
        if (file) {
          this.formData['image'] = await ezy.toBase64(file);
          this.formData['file_name'] = URL.createObjectURL(file);
        }
      },
      async save() {
        this.errors = {};
        if (!this.formData.user_id) {
          this.errors['user_id'] = 'Required';
          return false;
        }
        try {
          if (this.$route.params.id) {
            let res = await axios.put('customers/' + this.$route.params.id, this.formData);
            $iz.alert('success', this.$t('successfully_updated'), 'success');
          } else {
            let res = await axios.post('customers', this.formData);
            this.$router.push('/customers');
          }
        } catch (error) {
          this.errors = error.response.data.errors;
        }
      }
    }
  }
</script>


<style scoped>
  .img {
    width: 100px;
    height: 100px;
    overflow: hidden;
    text-align: center;
  }
  select {
    color: black !important;
  }
</style>