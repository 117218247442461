export default [
    { type: 'menu', title: $t('dashboard'), route: '/', icon: 'box', color: 'text-orange'},
    
    {type: 'header', title: $t('list')},
    { type: 'menu', title: $t('customer'), route: '/customers', icon: 'users', color: 'text-orange'},
    { type: 'menu', title: $t('vehicle'), route: '/vehicles', icon: 'truck', color: 'text-orange'},
    { type: 'menu', title: $t('admin'), route: '/admins', icon: 'users', color: 'text-orange'},

    { type: 'header', title: $t('history')},
    { type: 'menu', title: $t('histories'), route: '/history', icon: 'archive', color: 'text-orange'},
    { type: 'menu', title: $t('print_qr_code'), route: '/customers/qr', icon: 'trello', color: 'text-orange'},
]
