<template>
  <footer class="footer no-print d-flex flex-column flex-md-row align-items-center justify-content-between">
    <p class="text-muted text-center text-md-left">Copyright © {{ moment().format('YYYY') }} <a
        href="https://www.programmingdude.dev/" target="_blank">Programming Dude</a>. All rights reserved</p>
  </footer>
</template>

<script>
  import moment from 'moment';
  export default {
    data() {
      return {
        moment
      }
    },
  }
</script>