<template>
    <div class="main-wrapper" id="app">
        <div class="page-wrapper full-page">
            <div class="page-content d-flex align-items-center justify-content-center">
                <div class="row w-100 mx-0 auth-page">
                    <div class="col-md-8 col-xl-6 mx-auto">
                        <div class="card">
                            <div class="row">
                                <div class="col-md-8 offset-md-2 pl-md-0">
                                    <div class="auth-form-wrapper px-4 py-5">
                                        <div class="text-center">
                                          <img src="/images/logo.png" width="70">
                                          <a href="#" class="noble-ui-logo d-block mb-2">
                                            Lucky Star
                                          </a>
                                          <h5 class="text-muted font-weight-normal mb-4">{{$t('welcome_back_login')}}</h5>
                                          <div class="alert alert-danger text-center" v-if="fail">
                                              <strong>{{$t('login_fail')}}</strong>
                                          </div>
                                        </div>
                                        
                                        <form @submit.prevent="login" class="forms-sample">
                                            <div class="form-group">
                                                <label for="username">{{$t('username')}}
                                                  <small v-if="error.username" class="text-danger">{{$t('required')}}</small>
                                                </label>
                                                <input v-model="params.username" type="text" class="form-control" :placeholder="$t('username')">
                                            </div>
                                            <div class="form-group">
                                                <label for="password">{{$t('password')}}
                                                  <small v-if="error.password" class="text-danger">{{$t('required')}}</small>
                                                </label>
                                                <input v-model="params.password" type="password" class="form-control" autocomplete="current-password" :placeholder="$t('password')">
                                            </div>
                                            <div class="form-group">
                                                <button type="submit" class="btn btn-primary btn-block">{{$t('login')}}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            error: {},
            params: {
                username : null,
                password : null,
            },
            fail : false
        }
    },
    methods: {
        login() {
            this.error = {};
            if(!this.params.username) {
                this.error.username = true;
            }
            if(!this.params.password) {
                this.error.password = true;
            }
            if( ezy.objectLength(this.error) > 0) {
                return false;
            }
            else {
                this.fail = false;
                this.$auth.login({
                    data: this.params, 
                    rememberMe: true,
                    redirect: '/',
                    fetchUser: true
                })
                .then((res) => {
                    console.log(res);
                }, (res) => {
                    this.fail = true;
                    console.log(res);
                });
            }
        }
    }
}
</script>
<style scoped>
.water-image {
  width: 100%
}
</style>
