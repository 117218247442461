import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';

let app = {};

app.confirm = function(message, callback , confirm = 'OK') {
    iziToast.destroy();
    iziToast.question({
        timeout: false,
        overlay: true,
        id: 'question',
        zindex: 999999999,
        title: 'CONFIRM',
        message: message,
        position: 'topCenter',
        buttons: [
            ['<button>CANCEL</button>', function (instance, toast) {
                callback(false);
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }],
            [`<button class='bg-danger text-white'><b>${confirm}</b></button>`, function (instance, toast) {
                callback(true);
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }],
        ],
    });
}

app.prompt =  function(message, callback , defaultValue = '') {
    iziToast.destroy();
    iziToast.question({
        timeout: false,
        overlay: true,
        id: 'question',
        zindex: 999,
        title: 'PROMPT',
        message: message,
        position: 'topCenter',
        drag: false,
        buttons: [
            ['<button>CANCEL</button>', function (instance, toast) {
                callback(false);
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }],
            [`<button id='prompt-2-submit' class='bg-danger text-white'><b>SUBMIT</b></button>`, function (instance, toast) {
                let value = $('#prompt-2-input').val();
                callback(value);
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }],
        ],
        inputs: [
            [`<input id="prompt-2-input" value='${defaultValue}' style="width:300px" type="text">`, 'keyup', function (instance, toast, input, e) {
                if(e.keyCode == 13) {
                    $('#prompt-2-submit').click();
                }
            }, true]
        ]
    });
}

app.alert = function(type , message , title) {
    iziToast.destroy();
    let timeout = 2000;
    if(type == 'success') {
        iziToast.success({
            theme: 'dark',
            title: (title)?title:"SUCCESS",
            message: message,
            position: 'topRight',
            timeout : timeout,
            backgroundColor: '#fb633f',
            titleColor: '#ffffff',
            iconColor: '#ffffff',
            color: '#ffffff',
            messageColor: '#ffffff',
        });
    }
    
    if(type == 'info') {
        iziToast.warning({
            title: (title)?title:"INFO",
            message: message,
            position: 'topRight',
            timeout : timeout
        });
    }
   
    if(type == 'error') {
        iziToast.error({
            title: (title)?title:"ERROR",
            message: message,
            position: 'topRight',
            timeout : 3000
        }); 
    }
}


export default app;