import moment from 'moment-timezone';
const ezy  = {};

ezy.comma = (val, round = 0)=> {
    const options = {
      minimumFractionDigits: round,
      maximumFractionDigits: round
    }
    return Number(val).toLocaleString('en', options)
}
/* eslint-disable */
ezy.str2number = (val)=> {
	val  = val+'';
	return parseFloat(val.replace( /[^\d\.\-]/g, ""));
}

/* eslint-disable */
ezy.round = (val,decimals) =>{ 
	var y = ezy.str2number(val);
		y = (Math.abs(y) > 0.000000001)?y:0;
		if( decimals === 0 ){
			decimals = 0;
		}else{
			decimals = (decimals)?decimals:2;
		}
	var d = decimals*1+8;
		d = (d > 12)?12:d;
		y = Number(Math.round((y).toFixed(d)+'e'+d)+'e-'+d).toFixed(d);
		res = Number(Math.round((y+"").replace(/[^0-9.-]/g,'')+'e'+decimals)+'e-'+decimals).toFixed(decimals); 
	return ezy.str2number(res);
};
/* eslint-disable */
ezy.isMail = (mail)=> {
	let emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
	
	if(!mail.match(emailReg)){
		return false
	} 
	else return true;      
}

ezy.formatDate = (date , format ='DD-MMM-YYYY')=> {
    var m = moment(date);
    return (m.isValid())? moment(date).tz('Asia/Yangon')
    .format(format):'';
}

ezy.objectLength = (obj)=> {
    return Object.keys(obj).length
}

ezy.findIndex = (array , [key , value] )=>{
    return array.findIndex(x => x[key] == value );
}

ezy.lastUpdateAt = (updated_at) => {
    let now = new Date().getTime();
    let updated = new Date(updated_at).getTime();
    let different = now - updated;
    let days = Math.floor(different / 1000 / 60 / (60 * 24));
    let hours = Math.floor(different / 1000 / 60 / 60);
    let minutes = Math.floor(different / 1000 / 60 );
    if(days > 0)
    {
        return days+'d'
    } 
    else if(hours > 0) 
    {
        return hours+"h"
    }
    else if(minutes > 0) 
    {
        return minutes+"m"
    }
    else {
        return "a few time"
    }
}

ezy.isoDate = (date = null) => {
    var d = (date)?new Date(date): new Date;
    var isoDate = new Date(d.getTime() - (d.getTimezoneOffset() * 60000)).toISOString();
    return isoDate;
}

ezy.bytesToSize = (bytes)  => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}
/* eslint-disable */
ezy.confirm = (text, button = 'YES') => {
    return new Promise( async (resolve, reject) => {
        let res = await Swal.fire({
            title              : text,
            html               : '',
            icon               : "warning",
            showCancelButton   : true,
            confirmButtonColor : "#0aa699",
            cancelButtonColor  : "#949697",
            confirmButtonText  : button
        })
        if(res.value) {
            resolve(true);
        }
    })
}
ezy.shortString = (str, maxLen = 100, separator = '') => {
    if (str.length <= maxLen) return str;
    return str.substr(0, str.lastIndexOf(separator, maxLen)) + ' ...';
}

ezy.showModal = (id) => {
    $(`#${id}`).modal('show');
}

ezy.hideModal = (id) => {
    $(`#${id}`).modal('hide');
}

//base 64 string converter
ezy.toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

ezy.urlToBase64 = (url) =>  {
    return new Promise(resolve => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
                resolve(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.onerror = function() {
            resolve(null);
        }
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    });
}

ezy.prettyNumberFormat = (number, pad) => {
    return (pad + number).slice(-pad.length)
}

ezy.formatTime = (time , format ='hh:mm A')=> {
    var m = moment(time);
    return (m.isValid())? moment(time).tz('Asia/Yangon')
    .format(format):'';
}


export default ezy;