<!-- Global Drop Down Menu -->
<template>
    <div class="dropdown">
        <button :class="'btn btn-'+config.color+' btn-sm'" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{config.title}} 
            <i class="fas fa-angle-down"></i>
        </button>
        <div class="dropdown-menu  dropdown-menu-right ">
            <div class="dropdown-header noti-title" v-if="config.header">
                <h6 class="text-overflow m-0">{{config.header}}</h6>
            </div>
            <span v-for="(item,index) in config.child" :key="index">
                <a :class="'dropdown-item pointer text-'+color(item.color)" @click="eventEmit(item)">
                    <span v-html="item.icon"></span>
                    <span>&nbsp; {{item.title}}</span>
                </a>
                <div class="dropdown-divider" v-if="item.divider"></div>
            </span>
            
        </div>
    </div>
</template>

<script>
export default {
    props : {
        config: {
            type: Object,
            default : {}
        },
    },
    data() {
        return {
            
        }
    },
    methods: {
        color(color) {
            return (color)?color:'black';
        },
        eventEmit(item) {
            let tmp = item.event.split('@');
            if(tmp.length > 1) {
                this.$emit(tmp[0], tmp[1]);
            }
            else {
                this.$emit(item.event, true)
            }
            
        }
    }
}
</script>