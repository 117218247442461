export default {
  username: 'User Name',
  vehicle_number : 'Vehicle No.',
  password: 'Password',
  password_wrong: 'Password Wrong',
  bottle: 'bottle',
  are_you_sure_to_delete: 'Are You Sure To Delete?',
  kyat: 'Kyat',
  yes_delete: 'Delete',
  no_delete: 'Cancel',
  logout: 'Logout',
  manual: 'Manual',
  total_bottle: 'Total Bottle',
  quantity: 'Quantity',
  type_total_bottle: 'Type Total Bottle',
  submit: 'Submit',
  wrong_qr_code: 'Wrong QR Code',
  error: 'Error',
  bottle_amount_error: 'Bottle quantity must be at least 1!',
  customer_not_selected_error: 'Please select customer!',
  are_you_sure: 'Are You Sure?',
  customer_not_found: 'Customer Not Found',
  success: 'Success',

  customer: 'Customer',
  are_you_sure_to_logout: 'Are You Sure To Logout?',
  admin: 'Admin',
  vehicle: 'Vehicle',
  today_sale: 'Today Sale',
  today_revenue: 'Today Revenue',
  total : 'Total',
  today_scan_report : 'Today Scan Report',
  no : 'No .',
  first_sale_time: 'First Sale Time',
  last_sale_time: 'Last Sale Time',
  first_customer: 'First Customer',
  last_customer: 'Last Customer',
  no_scan_report: 'No Scan Report',

  customer_list: 'Customer Lists',
  search : 'Search...',
  start_date: 'Started Date',
  last_sent_date: 'Last Sent Date',
  last_vehicle: 'Driver',
  create: 'Create',
  day: 'Day',
  new_customer: 'New Customer',
  edit_customer: 'Update Customer',
  full_name: 'Full Name',
  name: 'Name',
  required: 'Required',
  phone_number: 'Phone Number',
  phone: 'Phone',
  full_address: 'Full Address',
  address: 'Address',
  advance: 'Deposit',
  price: 'Price',
  bottle_price: 'Bottle Price',
  total_bottle_take: 'Total Bottle',
  update: 'Update',
  submit: 'Submit',

  admin_list: 'Admin Lists',
  created_date: 'Created Date',
  edit: 'Edit',
  delete: 'Delete',
  user_image: 'User Image',
  change_password: 'Change Password',
  save: 'Save',
  cancle: 'Cancel',
  vehicle_list: 'Vehicle Lists',
  driver_name: 'Driver Name',
  total_bottle_per_month: 'Total Bottle / Month',

  history_log: 'History Logs',
  search_botton: 'Search',
  date_and_time: 'Date / Time',

  print: 'Print',
  back: 'Back',

  login: 'Login',
  welcome_back_login: 'Welcome back! Log in to your account.',
  login_fail: 'Login Fail',

  dashboard: 'Dashboard',
  list: 'Lists',
  history: 'History',
  histories: 'History Log',
  print_qr_code: 'Print QR Code',

  successfully_deleted: 'Successfully Deleted',
  successfully_updated: 'Successfully Updated',
  type_at_least_four: 'Require At Least Four',
  username_already_taken: 'Username already taken!',
}