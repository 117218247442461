<template>
  <nav class="navbar no-print">
    <a href="#" class="sidebar-toggler">
      <i data-feather="menu"></i>
    </a>
    <div class="navbar-content">
      <ul class="navbar-nav">
        <li class="nav-item dropdown">
          <a href="#" class="nav-item nav-link mr-md-2" data-toggle="dropdown">
            <span class="dropdown-toggle">Language</span>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <a @click="changeLanguage('mm')" class="pointer dropdown-item">မြန်မာ</a>
            <div class="dropdown-divider"></div>
            <a @click="changeLanguage('en')" class="pointer dropdown-item">English</a>
          </div>
        </li>

        <li class="nav-item dropdown nav-profile">
          <a class="nav-link dropdown-toggle" href="#" id="profileDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <img @error="setDefaultImage" :src="$auth.user().image" alt="profile">
          </a>
          <div class="dropdown-menu" aria-labelledby="profileDropdown">
            <div class="dropdown-header d-flex flex-column align-items-center">
              <div class="figure mb-3">
                <img @error="setDefaultImage" :src="$auth.user().image" alt="">
              </div>
              <div class="info text-center">
                <p v-if="$auth.ready() && $auth.user()" class="name text-capitalize font-weight-bold mb-0">
                  {{$auth.user().full_name}}</p>
              </div>
            </div>

            <div class="dropdown-body">
              <ul class="profile-nav p-0 pt-3">
                <li class="nav-item">
                  <a href="javascript:;" @click="$auth.logout();$router.push('/login')" class="nav-link">
                    <i data-feather="log-out"></i>
                    <span>{{$t('logout')}}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
  export default {
    methods: {
      changeLanguage(lang) {
        window.localStorage.setItem('lang', lang);
        window.location.reload();
      },
      setDefaultImage(event) {
        event.target.src = '/images/avatar.png';
      },
    }
  }
</script>