<template>
  <div class="row">
    <div class="col-12">
      <div class="alert alert-danger" v-if="error">
        <p class="mb-0"> {{error}}</p>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group mb-3">
            <label for="">Vehicle Number</label>
            <select @change="searchCustomersByAdmin">
              <option :value="null">All</option>
              <option v-for="(v,index) in vehicles" :key="v.id" :value="index">
                {{v.username}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <Multiselect multiple :options="users" v-model="selected_users" :placeholder="$t('customer')"
        @search-change="searchCustomers" :custom-label="customLabel" label="full_name" :show-labels="false"
        track-by="id" />

      <button class="btn btn-primary mt-3" @click="print()">
        <i class="mdi mdi-printer"></i>
        {{$t('print')}}
      </button>
    </div>

  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import "vue-multiselect/dist/vue-multiselect.min.css"
  export default {
    components: {
      Multiselect,
    },
    data() {
      return {
        users: [],
        userIndex: null,
        keyword: null,
        selected_users: [],
        error: null,
        vehicles: []
      }
    },
    mounted() {
      this.getCustomers();
      this.getVehicles();
    },
    methods: {
      async getVehicles() {
        let params = {
          role: 'vehicle',
          page: 1,
          limit: 'all',
          sorts: [{
            column: "username",
            type: "asc"
          }]
        }
        let res = await axios.get('users', {
          params
        });
        if (res) {
          this.vehicles = res.data.data;
        }
      },
      searchCustomersByAdmin(d) {
        this.userIndex = d.target.value;
        this.getCustomers();
      },
      searchCustomers(keyword) {
        if(!keyword.isTrusted) {
          this.keyword = keyword;
          this.getCustomers();
        }
        else {
          this.getCustomers();
        }
      },
      async getCustomers() {
        if(this.userIndex !=  null) {
          var {id} = this.vehicles[this.userIndex];
        }
        else {
          var id  = null
        }
        let res = await axios.get('all_customers', {
          params: {
            keyword: this.keyword,
            user_id: id
          }
        });
        this.users = res.data.data;
      },
      customLabel({
        full_name,
        address,
        phone
      }) {
        return `${full_name} \n ( ${address} )`
      },
      print() {
        if(this.userIndex) {
          var {username} = this.vehicles[this.userIndex];
        }
        else {
          var username  = null
        }
        console.log(username);

        if (this.selected_users.length > 20) {
          this.error = "Not More than 20 customers";
        } else {
          let ids = this.selected_users.map(u => {
            return u.id
          })
          this.$router.push('/qr-pdf/' + JSON.stringify(ids) + '/' + username) 
        }
      }
    },
  }
</script>