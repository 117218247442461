<template>
    <div class="main-wrapper">
        <SidebarLayout />
        <div class="page-wrapper">
            <HeaderLayout />
            <div class="page-content">
                <router-view />
            </div>
            <FooterLayout />
        </div>
    </div>
</template>

<script>
import HeaderLayout from './layouts/Header'
import SidebarLayout from './layouts/Sidebar'
import FooterLayout from './layouts/Footer'

export default {
    components: {
        HeaderLayout,
        SidebarLayout,
        FooterLayout
    },
    mounted() {
        feather.replace();
    }
}
</script>
