export default {
  username: 'အသုံးပြုသူအမည် ',
  vehicle_number : 'ယာဉ်အမှတ်',
  password: 'စကားဝှက်',
  password_wrong: 'မှားယွင်း နေသည်',
  bottle: 'ဘူး',
  are_you_sure_to_delete: 'အား ဖျက်ရန် သေချာပါ သလား?',
  kyat: 'ကျပ်',
  yes_delete: 'ဖျက်မည်',
  no_delete: 'မဖျက်ပါ',
  logout: 'ထွက်ရန်',
  manual: 'ရွေးချယ်မည်',
  total_bottle: 'ဘူးအရေအတွက်',
  quantity: 'အရေအတွက်',
  type_total_bottle: 'ဘူးအရေအတွက်အား ရိုက်သွင်း မည်',
  submit: 'ထည့်သွင်းမည်',
  wrong_qr_code: 'QR Code မှားယွင်း နေသည်',
  error: 'မှားယွင်း နေသည်',
  bottle_amount_error: 'ဘူးအရေအတွက် အနည်း ဆုံး ၁ ဘူး ဖြစ်ရမည်',
  customer_not_selected_error: 'ဖောက် သည် အား ရွေး ချယ်ရန် လိုအပ် သည်',
  are_you_sure: 'သေချာ ပါသလား?',
  customer_not_found: 'ဖောက်သည် အား ရှာ မတွေ့ပါ',
  success: 'အောင်မြင်သည်',


  customer: 'ဖောက်သည်',
  are_you_sure_to_logout: 'ထွက်ရန် သေချာ ပါသလား?',
  admin: 'အက်မင်',
  vehicle: 'ယာဉ်',
  today_sale: 'ယနေ့ရောင်းအား',
  today_revenue: 'ယနေ့ရောင်းရငွေ',
  total : 'စုစုပေါင်း',
  today_scan_report : 'ယနေ့ စကင်ဖတ် ထားသော စာရင်း',
  no : 'No .',
  first_sale_time: 'ပထမဆုံး ရောင်းရသော အချိန်',
  last_sale_time: 'နောက်ဆုံး ရောင်းရသော အချိန်',
  first_customer: 'ပထမဆုံး ဖောက်သည်',
  last_customer: 'နောက်ဆုံး ဖောက်သည်',
  no_scan_report: 'ယနေ့ စကင်ဖတ်ထား မှု မရှိပါ',

  customer_list: 'ဖောက်သည် စာရင်း',
  search : 'ရှာဖွေရန်...',
  start_date: 'စတင် သုံးစွဲခဲ့ သောနေ့',
  last_sent_date: 'နောက် ဆုံးပေးပို့ ခဲ့သောနေ့',
  last_vehicle: 'ပေးပို့သူ',
  create: 'ဖန်တီးရန်',
  day: 'ရက်',
  new_customer: 'ဖောက်သည် အသစ် ထည့်သွင်း ရန်',
  edit_customer: 'ဖောက်သည် အချက်အလက် ပြုပြင်ရန်',
  full_name: 'အမည် အပြည့်အစုံ',
  name: 'အမည်',
  required: 'လိုအပ်သည်',
  phone_number: 'ဖုန်း နံပါတ်',
  phone: 'ဖုန်း',
  full_address: 'နေရပ် လိပ်စာ အပြည့် အစုံ',
  address: 'လိပ်စာ',
  advance: 'စရံငွေ',
  price: 'တန်ဖိုး',
  bottle_price: 'ရေတစ်ဘူး တန်ဖိုး',
  total_bottle_take: 'ယူထားသော ဘူး အရေအတွက်',
  update: 'ပြုပြင် မည်',
  submit: 'ထည့်သွင်း မည်',

  admin_list: 'အက်မင် စာရင်း',
  created_date: 'ဖန်တီးခဲ့ သောနေ့စွဲ',
  edit: 'ပြင်ဆင်ရန်',
  delete: 'ဖျက်ဆီးရန်',
  user_image: 'အသုံးပြုသူ ပုံ',
  change_password: 'စကားဝှက် ပြောင်းလဲရန်',
  save: 'သိမ်းဆည်းမည်',
  cancle: 'ပယ်ဖျက်မည်',
  vehicle_list: 'ယာဉ်စာရင်း',
  driver_name: 'ယာဉ်မောင်းအမည်',
  total_bottle_per_month: 'ယခု လ အတွင်း ပေး ပို့ခဲ့ သော ဘူး အရေအတွက်',

  history_log: 'မှတ်တမ်း ရှာဖွေရန်',
  search_botton: 'ရှာဖွေမည်',
  date_and_time: 'အချိန် /နေ့ရက်',

  print: 'Print',
  back: 'Back',

  login: 'Login',
  welcome_back_login: 'Welcome back! Log in to your account.',
  login_fail: 'Login Fail',

  dashboard: 'Dashboard',
  list: 'စာရင်း',
  history: 'မှတ်တမ်း',
  histories: 'မှတ်တမ်းများ',
  print_qr_code: 'QR ကုဒ်ထုတ်ယူရန်',

  successfully_deleted: 'အောင်မြင်စွာ ဖျက်ပြီးပါပြီ',
  successfully_updated: 'successfully updated',
  type_at_least_four: 'အနည်းဆုံး လေးလုံး ရိုက်သွင်း ပါ',
  username_already_taken: 'Username already taken!',
}