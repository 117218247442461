<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h3 class="float-left card-title">{{$t('admin_list')}}</h3>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th height="50">{{$t('no')}}</th>
                <th> username </th>
                <th>{{$t('full_name')}}</th>
                <th>{{$t('created_date')}}</th>
                <th class="text-right">
                  <button v-if="$auth.user().role == 'super_admin'" @click="createData()" type="button"
                    class="float-right btn btn-xs btn-success">
                    <i class="mdi mdi-plus-circle pointer"></i>
                    {{$t('create')}}
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user,index) in users" :key="index">
                <td>{{ ( (params.page -1) *  params.limit ) + index + 1 }}</td>
                <td>
                  <img @error="setDefaultImage" :src="user.image" alt="" class="mr-2"> {{user.username}}
                </td>
                <td>
                  {{user.full_name}}
                </td>
                <td>
                  {{ ezy.formatDate(user.created_at) }}
                </td>
                <td class="text-right">
                  <button v-if="$auth.user().role == 'super_admin'" @click="editData(index)"
                    class="btn btn-xs btn-secondary mr-2">
                    <i class="mdi mdi-checkbox-marked-outline"></i>
                    {{$t('edit')}}
                  </button>
                  <button v-if="$auth.user().role == 'super_admin'" @click="deleteData(index)"
                    class="btn btn-xs btn-danger">
                    <i class="mdi mdi-delete-variant"></i>
                    {{$t('delete')}}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-footer">
        <b-pagination align="right" v-model="params.page" :total-rows="total" :per-page="params.limit"
          @change="pageChange" pills />
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="card">
            <div class="card-body px-3">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{$t('admin')}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <!-- <div class="form-group row">
                  <label class="col-sm-4 col-form-label">{{$t('user_image')}}</label>
                  <div class="col-sm-8">
                    <div class="mb-2 img">
                      <img width="100" :src="formData.file_name" alt="avatar">
                    </div>
                    <input ref="attachment" @change="onFileSelected" accept="image/*" type="file">
                  </div>
                </div> -->
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">{{$t('username')}}</label>
                  <div class="col-sm-8">
                    <small v-if="errors.username" class="text-danger"> {{errors.username}} </small>
                    <small v-if="error" class="text-danger"> {{error}} </small>
                    <input v-model="formData.username" type="text" class="form-control" :placeholder="$t('username')">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">{{$t('full_name')}}</label>
                  <div class="col-sm-8">
                    <small v-if="errors.full_name" class="text-danger"> {{errors.full_name}} </small>
                    <input v-model="formData.full_name" type="text" class="form-control" :placeholder="$t('full_name')">
                  </div>
                </div>

                <!-- toggle -->
                <div>
                  <div v-if="type == 'edit'">
                    <div @click="togglePasswordBox()">
                      <i class="mdi mdi-lock pointer"></i>
                      <span class="ml-2 text-primary pointer">{{$t('change_password')}}</span>
                    </div>
                  </div>

                  <div v-if="showPassword">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">{{$t('password')}}</label>
                      <div class="col-sm-8">
                        <small v-if="errors.password" class="text-danger"> {{errors.password}} </small>
                        <input v-model="formData.password" type="password" class="form-control"
                          :placeholder="$t('password')">
                      </div>
                    </div>
                  </div>

                </div>
                <!-- tpg -->

                <br>
                <div>
                  <button @click="saveData" class="btn btn-success mr-2">{{$t('save')}}</button>
                  <button class="btn btn-light" @click="cancelModal()">{{$t('cancle')}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        users: [],
        formData: {
          username: null,
          full_name: null,
          file_name: '/images/avatar.png',
          image: null,
          role: 'admin',
          password: null
        },
        params: {
          role: 'admin',
          page: 1,
          limit: 20,
          sorts: [{
            column: 'username',
            type: 'desc'
          }]
        },
        total: 0,
        type: 'create',
        showPassword: true,
        editIndex: null,
        errors: {},
        error: null
      }
    },
    mounted() {
      this.getData();
    },
    methods: {
      setDefaultImage(event) {
        event.target.src = '/images/avatar.png';
      },

      cancelModal() {
        $("#exampleModal").modal('hide');
      },
      togglePasswordBox() {
        this.showPassword = !this.showPassword
      },
      async getData() {
        let res = await axios.get('users', {
          params: this.params
        });
        if (res) {
          this.total = res.data.total;
          this.users = res.data.data;
        }
      },

      pageChange(page) {
        this.params.page = page;
        this.getData();
      },

      async onFileSelected(event) {
        let file = event.target.files[0];
        if (file) {
          this.formData['image'] = await ezy.toBase64(file);
          this.formData['file_name'] = URL.createObjectURL(file);
        }
      },

      createData() {
        this.formData = {
          username: null,
          full_name: null,
          file_name: '/images/avatar.png',
          image: null,
          role: 'admin',
          password: null
        };
        $("#exampleModal").modal('show');
        this.type = 'create'
        this.editIndex = null
        this.showPassword = true;
      },

      async saveData(e) {
        this.error = null;
        this.errors = {};
        let data = this.formData;

        if (!data.username) {
          this.errors.username = this.$t('required')
        }
        if (!data.full_name) {
          this.errors.full_name = this.$t('required')
        }
        if (this.type == 'create') {
          if (!data.password) {
            this.errors.password = this.$t('required')
          }
        } else {
          if (this.showPassword && !data.password) {
            this.errors.password = this.$t('required')
          }
          if (this.showPassword && data.password.length < 4) {
            this.errors.password = this.$t('type_at_least_four')
          }
        }

        if (Object.keys(this.errors).length == 0) {
          try {
            if (this.type == 'create') {
              let res = await axios.post('users', this.formData);
            } else {
              let {
                id
              } = this.users[this.editIndex];
              let res = await axios.put('/users/' + id, this.formData)
            }
            this.cancelModal();
            this.getData();
          } catch (error) {
            this.error = this.$t('username_already_taken')
          }
        }
      },

      async editData(index) {
        let {
          username,
          image,
          full_name,
          role
        } = this.users[index]
        this.editIndex = index;
        this.formData['username'] = username;
        this.formData['full_name'] = full_name;
        if (image) {
          this.formData['file_name'] = image;
          let content = await ezy.urlToBase64(image);
          if(content) {
            this.formData['image'] = content;
          }
        } else {
          this.formData['file_name'] = '/images/avatar.png';
        }
        this.formData['role'] = role;
        this.type = 'edit'
        this.showPassword = false;
        $("#exampleModal").modal('show');
      },

      async deleteData(index) {
        let {
          id
        } = this.users[index]
        if (await ezy.confirm(this.$t('are_you_sure'), 'DELETE')) {
          let res = await axios.delete(`users/${id}`);
          if (res) {
            this.users.splice(index, 1);
            $iz.alert('success', this.$t('successfully_deleted'), 'success');
          }
        }
      },
    }
  }
</script>

<style scoped>
  .img {
    width: 100px;
    height: 100px;
    overflow: hidden;
    text-align: center;
  }
</style>