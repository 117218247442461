import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    { path: '/', component: render('Home'),
        children : [
            { path: '/', component: render('pages/Dashboard') },
            { path: 'admins', component: render('pages/Admin') },
            { path: 'vehicles', component: render('pages/Vehicle') },
            { path: 'customers', component: render('pages/Customer') },
            { path: 'customers/qr', component: render('pages/QR') },
            { path: 'customers/create', component: render('pages/NewCustomer') },
            { path: 'customers/update/:id', component: render('pages/NewCustomer') },
            { path: 'history', component: render('pages/History') },
        ], meta : {auth : true}
    },
    { path: '/login', component: render('Login'), meta: {auth: false} },
    { path: '/qr-pdf/:customers?/:vehicle?', component: render('pages/QRPdf'), meta: {auth: true} },
    { path: '*', component: render('404') },
]

function render(template) {
    return () => import(`@/views/${template}.vue`);
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
