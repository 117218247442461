import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store.js'
import '@/scss/main.scss'
import '@/global_components/components'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL+'/api';
Vue.router = router;

import VueAuth from '@websanova/vue-auth'
import Authbearer from '@websanova/vue-auth/drivers/auth/bearer'
import AuthAxios from '@websanova/vue-auth/drivers/http/axios.1.x'
import AuthRouter from '@websanova/vue-auth/drivers/router/vue-router.2.x'

Vue.use(VueAuth, {   
    auth: Authbearer,   
    http: AuthAxios,   
    router: AuthRouter,
    loginData: {method: 'POST', fetchUser: true},
    fetchData: {method: 'GET'},
    authRedirect: {path: '/login'},
});

window.axios = axios
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
