<template>
  <div>
    <nav class="sidebar no-print">
      <div class="sidebar-header">
        <a href="#" class="sidebar-brand text-center">
          <div class="logo">
            <img data-v-63ef12fa="" src="/images/logo.png" alt="" class="logo-image">
            <h5> Lucky Star </h5>
          </div>
        </a>
        <div class="sidebar-toggler not-active">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="sidebar-body">
        <ul class="nav">
          <div v-for="(sidebar,index) in sidebars" :key="index">

            <li :class="(index == 0)?'nav-item nav-category':'nav-item nav-category mt-3 padauk-font'"
              v-if="sidebar.type =='header'">{{sidebar.title}}</li>

            <li class="nav-item" v-else-if="sidebar.type == 'menu' && ( sidebar.sub && sidebar.sub.length > 0)">
              <a class="nav-link" data-toggle="collapse" :href="'#tab-sidebar'+index" role="button"
                :aria-expanded="subIsActive(sidebar.route, 'boolean')" :aria-controls="'tab-sidebar'+index">
                <i :class="['link-icon', sidebar.color]" :data-feather="sidebar.icon"></i>
                <span class="link-title">{{sidebar.title}}</span>
                <i class="link-arrow" data-feather="chevron-down"></i>
              </a>
              <div :class="subIsActive(sidebar.route)" :id="'tab-sidebar'+index">
                <ul class="nav sub-menu">
                  <li class="nav-item" v-for="(sub,index) in sidebar.sub" :key="index">
                    <router-link :to="sub.route" class="nav-link">{{sub.title}}</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item" v-else>
              <router-link :to="sidebar.route" class="nav-link">
                <i :class="['link-icon', sidebar.color]" :data-feather="sidebar.icon"></i>
                <span class="link-title">{{sidebar.title}}</span>
              </router-link>
            </li>
          </div>

        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
  import sidebars from '../../sidebar';
  export default {
    data() {
      return {
        sidebars
      }
    },
    mounted() {
      this.sidebarHover()
    },
    methods: {
      sidebarHover() {
        $(".sidebar .sidebar-body").hover(
          function () {
            if ($('body').hasClass('sidebar-folded')) {
              $('body').addClass("open-sidebar-folded");
            }
          },
          function () {
            if ($('body').hasClass('sidebar-folded')) {
              $('body').removeClass("open-sidebar-folded");
            }
          });
      },
      subIsActive(input, className = 'collapse show') {
        const paths = Array.isArray(input) ? input : [input]
        let res = paths.some(path => {
          return this.$route.path.indexOf(path) === 0 // current path starts with this path string
        })

        if (className == 'boolean') {
          return res;
        } else {
          if (res) {
            return className;
          } else {
            return 'collapse';
          }
        }
      }
    }
  }
</script>

<style scoped>
  .nav-link.active {
    color: #7a84f5 !important;
  }

  .padauk-font {
    font-family: 'Padauk';
  }

  .logo {
    display: flex;
  }

  .logo-image {
    width: 50px;
    display: flex;
    height: 50px;
  }

  .logo h5 {
    margin: auto;
  }
</style>