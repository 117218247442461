<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <h3 class="float-left card-title">{{$t('history_log')}}</h3>
          </div>
        </div><br>

        <!-- date -->
        <div class="row">
          <div class="col-md-3">
            <input @keyup.enter="getData()" v-model="params.keyword" type="search" class="form-control"
              :placeholder="$t('search')">
          </div>
          <div class="col-md-3">
            <b-form-datepicker class="fix" placeholder="From Date" v-model="params.from_date"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }">
            </b-form-datepicker>
          </div>
          <div class="col-md-3">
            <b-form-datepicker class="fix" placeholder="From Date" v-model="params.to_date"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }">
            </b-form-datepicker>
          </div>
          <div class="col-md-3">
            <button @click="getData()" type="button" class="btn btn-block btn-primary">
              {{$t('search_botton')}}
            </button>

          </div>
          <div class="col-md-12 mt-3">
            <Multiselect multiple :options="users" v-model="selected_users" :placeholder="$t('vehicle_number')"
              label="username" track-by="id" @input="updateData" />
          </div>

        </div>
        <!-- date -->


        <div class="tabel-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th height="50">{{$t('no')}}</th>
                <th>{{$t('customer')}}</th>
                <th>{{$t('vehicle_number')}}</th>
                <th class="text-center">{{$t('total_bottle')}}</th>
                <th>{{$t('price')}}</th>
                <th class="text-center">{{$t('date_and_time')}}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(history,index) in data" :key="index">
                <td>{{ ( (params.page -1) *  params.limit ) + index + 1 }}</td>
                <td>
                  <span v-if="history.customer"> {{history.customer.full_name}}</span>
                  <p v-if="history.customer"><small>{{history.customer.address}}</small></p>
                </td>
                <td>
                  {{history.user.full_name}}
                </td>
                <td class="text-center">
                  {{history.amount}} {{$t('bottle')}}
                </td>
                <td>
                  {{history.price}} {{$t('kyat')}}
                </td>
                <td class="text-center">
                  {{ezy.formatDate(history.created_at, 'DD-MMM-YYYY hh:mm A')}}
                </td>
                <td class="text-right" style="width : 80px">
                  <b-button variant="danger" @click="deleteRecord(index)" >Delete</b-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-footer">
        <b-pagination align="right" v-model="params.page" :total-rows="total" :per-page="params.limit"
          @change="pageChange" pills />
      </div>
    </div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import "vue-multiselect/dist/vue-multiselect.min.css"
  import moment from 'moment-timezone';
  export default {
    components: {
      Multiselect,
    },
    data() {
      return {
        data: [],
        users: [],
        selected_users: [],
        params: {
          "keyword": "",
          "from_date": moment().tz('Asia/Yangon').startOf('month').format('YYYY-MM-DD'),
          "to_date": moment().tz('Asia/Yangon').format('YYYY-MM-DD'),
          "user_ids": [],
          "page": 1,
          "limit": "20",
          "sorts": [{
            "column": "created_at",
            "type": "desc"
          }]
        },
        total: 0
      }
    },
    mounted() {
      this.getUser();
      this.getData();
    },
    methods: {
      async getUser() {
        let params = {
          role: 'vehicle',
          page: 1,
          limit: 'all',
          sorts: [{
            "column": "username",
            "type": "asc"
          }]
        }
        let res = await axios.get('users', {
          params
        });
        if (res) {
          this.users = res.data.data;
        }
      },

      async getData() {
        if (this.params.user_ids.length == 0) {
          this.params.user_ids = "all"
        }
        let res = await axios.get('history-logs', {
          params: this.params
        });
        if (res) {
          this.data = res.data.data;
          this.total = res.data.total
        }
      },

      async deleteRecord(index) {
        let { id } = this.data[index];
        if (await ezy.confirm(this.$t('are_you_sure'), 'DELETE')) {
          let res = await axios.delete(`sales/${id}`);
          if (res) {
            this.data.splice(index, 1);
            this.total--;
            $iz.alert('success', this.$t('successfully_deleted'), 'success');
          }
        }
      },

      pageChange(page) {
        this.params.page = page;
        this.getData();
      },

      updateData(value) {
        let tmp = [];
        value.forEach(val => {
          tmp = [...tmp, val.id]
        })
        this.params.user_ids = tmp
      }
    }
  }
</script>
<style scoped>
  .img {
    width: 100px;
    height: 100px;
    overflow: hidden;
    text-align: center;
  }

  .custom-width {
    width: 190px;
  }

  .select-width {
    width: 190px;
  }
</style>