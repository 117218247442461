const loading = {} ;

loading.show = () => {
    VueApp.$store.commit('loading');
}

loading.hide = () => {
    VueApp.$store.commit('hideLoading');
}

export default loading;