<template>
  <div>
    <div class="card">
      <div class="card-body table-responsive">
        <div class="row mb-3">
          <div class="col-md-5">
            <h3 class="float-left card-title">{{$t('customer_list')}} ({{total}} ယောက်)</h3>
          </div>
          <div class="col-md-7 text-right">
            <JsonExcel
              class="btn btn-success mr-3"
              :fetch="fetchData"
              :fields="jsonFields"
              name="custmer_list.xls"
            >
            Download Excel
            </JsonExcel>
            <input type="search" @keyup.enter="getData" v-model="params.keyword" :placeholder="$t('search')"
              class="form-control search-box float-right">
            <select @change="getData" v-model="params.user_id">
              <option :value="null">All</option>
              <option v-for="v in vehicles" :key="v.id" :value="v.id">
                {{v.username}}
              </option>
            </select>
          </div>
        </div>
        <hr>
        <table class="table table-striped ">
          <thead>
            <tr>
              <th height="50">{{$t('no')}}</th>
              <th class="sort" data-column="full_name">{{$t('customer')}}</th>
              <th class="sort text-center" data-column="created_at">{{$t('start_date')}}</th>
              <th class="text-center sort" data-column="total_bottles">{{$t('total_bottle')}}</th>
              <th class="sort text-center" data-column="last_scan_date">{{$t('last_sent_date')}}</th>
              <th class="sort text-center" data-column="last_scan_user_id">{{$t('last_vehicle')}}</th>
              <th class="text-right">
                <router-link to="customers/create" type="button" class="float-right btn btn-xs btn-success">
                  <i class="mdi mdi-plus-circle pointer"></i>
                  {{$t('create')}}
                </router-link>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(customer,index) in customers" :key="index">
              <td>{{ ( (params.page -1) *  params.limit ) + ( index + 1 ) }}</td>
              <td>
                <!-- <img :src="customer.image" @error="setDefaultImage" alt="" class="mr-2" :id="'customer-detail-popover-'+index"> -->
                <span class="text-bold">{{customer.full_name}}</span>
                <b-popover placement="left" :target="'customer-detail-popover-'+index" triggers="hover focus">
                  <template #title>
                    {{customer.full_name}}
                  </template>
                  <p class="text-danger">{{$t('phone')}} - {{customer.phone}}</p>
                  <p>
                    {{$t('address')}} - {{customer.address}}
                  </p>
                  <p>
                    {{$t('advance')}} - {{ezy.comma(customer.advance)}} {{$t('kyat')}}
                  </p>
                  <p>
                    {{$t('bottle_price')}} - {{ezy.comma(customer.bottle_price)}} {{$t('kyat')}}
                  </p>
                </b-popover>
              </td>
              <td class="text-center">{{ ezy.formatDate(customer.created_at) }}</td>
              <td class="text-center">
                <span v-if="customer.total_bottles">{{customer.total_bottles}} {{$t('bottle')}} </span>
                <span v-else>0 {{$t('bottle')}}</span>
              </td>
              <td class="text-center">
                <span class="badge" :class="[ getColor(customer.days) ]" v-if="customer.last_scan_date != null">
                  {{ ezy.formatDate(customer.last_scan_date) }} ( {{customer.days}} {{$t('day')}} )
                </span>
                <span v-else>
                  -
                </span>
              </td>
              <td class="text-center">
                {{ (customer.vehicle) ? customer.vehicle.full_name : '-'}}
              </td>
              <td class="text-right">
                <router-link :to="'customers/update/'+customer.id" class="btn btn-xs btn-secondary mr-2">
                  <i class="mdi mdi-checkbox-marked-outline"></i>
                  {{$t('edit')}}
                </router-link>
                <button @click="deleteData(index)" class="btn btn-xs btn-danger">
                  <i class="mdi mdi-delete-variant"></i>
                  {{$t('delete')}}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer">
        <b-pagination align="right" v-model="params.page" :total-rows="total" :per-page="params.limit"
          @change="pageChange" pills />
      </div>
    </div>
  </div>
</template>

<script>
  import JsonExcel from "vue-json-excel";
  export default {
    components: {
      JsonExcel,
    },
    data() {
      return {
        customers: [],
        formData: {
          username: null,
          full_name: null,
          file_name: '/images/avatar.png',
          image: null,
          role: null,
          password: null
        },
        params: {
          user_id: null,
          keyword: '',
          page: 1,
          limit: 50,
          sorts: [{
            column: 'last_scan_date',
            type: 'desc'
          }]
        },
        total: 0,
        vehicles: [],
        jsonFields: {
          'Customer Name': 'full_name',
          'Phone': 'phone',
          'Address': 'address',
          'Deposit': 'advance',
          '1 Bottle Price': 'bottle_price',
          'Start Using date': 'created_at',
          'Total Bottles': 'total_bottles',
          'Last Scan Date': 'last_scan_date',
          'Total Days From Last Scan Date': 'days',
          'Vehicle name': 'vehicle.username',
        }
      }
    },
    mounted() {
      this.getData();
      this.initSort();
      this.getVehicles();
    },
    methods: {
      getColor(days) {
        if (days <= 3) {
          return 'badge-success'
        } else if (days > 3 && days <= 6) {
          return 'badge-warning'
        } else {
          return 'badge-danger'
        }
      },
      setDefaultImage(event) {
        event.target.src = '/images/avatar.png';
      },
      async fetchData(){
        let params = {...this.params};
        params.limit = 'all';
        let res = await axios.get('customers', { params });
        if (res) {
          return res.data.data;
        }
      },
      async getVehicles() {
        let params = {
          role: 'vehicle',
          page: 1,
          limit: 'all',
          sorts: [{
            column: "username",
            type: "asc"
          }]
        }
        let res = await axios.get('users', {
          params
        });
        if (res) {
          this.vehicles = res.data.data;
        }
      },
      async getData() {
        let res = await axios.get('customers', {
          params: this.params
        });
        if (res) {
          this.total = res.data.total;
          this.customers = res.data.data;
        }
      },
      pageChange(page) {
        this.params.page = page;
        this.getData();
      },
      initSort() {
        let self = this;
        $('.sort').click(function () {
          let column = $(this).attr('data-column')
          if (column) {
            let type = (self.params.sorts[0].type == 'asc') ? 'desc' : 'asc';
            self.params.sorts = [{
              column,
              type
            }]
            self.getData();
          }
        })
      },
      async deleteData(index) {
        let {
          id
        } = this.customers[index]
        if (await ezy.confirm(this.$t('are_you_sure'), 'DELETE')) {
          let res = await axios.delete(`customers/${id}`);
          if (res) {
            this.customers.splice(index, 1);
            this.total--;
            $iz.alert('success', this.$t('successfully_deleted'), 'success');
          }
        }
      },
    }
  }
</script>

<style scoped>
  .img {
    width: 100px;
    height: 100px;
    overflow: hidden;
    text-align: center;
  }

  .search-box {
    width: 200px
  }

  select {
    width: 200px;
    float: right;
    margin-right: 5px;
  }

  .day-green {
    background: green
  }

  .day-yellow {
    background: yellow
  }

  .day-red {
    background: red
  }
</style>