import './tools';
import Vue from 'vue';
import VueGlobalVar from 'vue-global-var';
import ezy from './ezy';
import $Loading from './loading';
import $iz from './izitoast';
import Swal from 'sweetalert2';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

global.Swal = Swal;

import lang from '../lang';
let $t = (keyword) => {
  let language = window.localStorage.getItem('lang');
  language = (language) ? language: 'mm';
  return (lang[language][keyword]) 
    ? (lang[language][keyword]) 
    : keyword;
}

Vue.use(VueGlobalVar, {
    globals: {
        ezy,
        $Loading,
        $iz,
        $t
    },
});