<template>
  <div>
    <!-- date -->
    <div class="row mb-4 dashboard">
      <div class="col-md-3"></div>
      <div class="col-md-3 text-right">
        <b-button variant="outline-primary" v-b-modal.modal-record>
          <b-icon icon="plus-lg" class="mr-2"></b-icon>
          Create Records
        </b-button>
        <b-modal size="xl" id="modal-record" v-model="show" title="Create Records">
          <div>
            <table class="table">
              <thead>
                <tr>
                  <th style="width: 50px;">
                    <b-button @click="addRecord()" variant="primary" size="xs" > <b-icon icon="plus-lg"></b-icon> </b-button>
                  </th>
                  <th style="width: 300px;">Diver</th>
                  <th>Customer</th>
                  <th style="width: 100px;">Total Bottle</th>
                  <th style="width: 150px;">Price</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(record,index) in records" :key="index">
                  <td> {{(index + 1)}}. </td>
                  <td>
                    <Multiselect
                      :options="users"
                      v-model="record.user"
                      :placeholder="$t('Driver')"
                      label="full_name"
                      :show-labels="false"
                      track-by="id"
                      @select="(driver) => {
                        record.customer = null;
                        filterCustomer(driver);
                      }"
                    />
                  </td>
                  <td>
                    <Multiselect
                      :options="customers"
                      v-model="record.customer"
                      :placeholder="$t('customer')"
                      @search-change="searchCustomers"
                      :custom-label="customLabel"
                      label="full_name"
                      @select="customerSelected($event, index)"
                      :show-labels="false"
                      track-by="id"
                    />
                  </td>
                  <td>
                    <b-form-input v-model="record.total_bottle" type="number"></b-form-input>
                  </td>
                  <td>
                    <b-form-input v-model="record.price" type="number"></b-form-input>
                  </td>
                  <td style="width: 50px;">
                    <b-button @click="delRecord(index)" variant="danger" size="xs" > 
                      <b-icon icon="trash-fill"></b-icon> 
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <template #modal-footer>
            <div class="w-100">
              <b-button variant="primary" class="float-right" @click="submitRecords()"> Submit </b-button>
            </div>
          </template>
        </b-modal>
        <!-- <b-form-datepicker class="fix" @input="getData()" placeholder="From Date" v-model="params.from_date"
          :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }">
        </b-form-datepicker> -->
      </div>
      <div class="col-md-3">
        <b-form-datepicker
          class="fix"
          @input="getData()"
          placeholder="From Date"
          v-model="params.from_date"
          :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
        >
        </b-form-datepicker>
      </div>
      <div class="col-md-3">
        <select @change="getData()" v-model="params.vehicle">
          <option :value="null">All</option>
          <option v-for="v in users" :key="v.id" :value="v.id">
            {{ v.username }}
          </option>
        </select>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-3 grid-margin">
        <div class="card">
          <div class="card-body text-success">
            <h6 class="card-title mb-0 text-success">
              {{ $t('customer') }}
            </h6>
            <small>{{ $t('quantity') }}</small>
            <div class="d-flex items-center">
              <i class="mdi mdi-human-greeting icon-5x"></i>
              <h5 class="ml-4 mt-3">{{ data.total_customer }}</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 grid-margin">
        <div class="card">
          <div class="card-body text-info">
            <h6 class="card-title text-info mb-0">
              {{ $t('vehicle') }}
            </h6>
            <small>{{ $t('quantity') }}</small>
            <div class="d-flex items-center">
              <i class="mdi mdi-car icon-5x"></i>
              <h5 class="ml-4 mt-3">{{ data.total_vehicle }}</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 grid-margin">
        <div class="card">
          <div class="card-body text-primary">
            <h6 class="card-title text-primary mb-0">{{ $t('today_sale') }}</h6>
            <small>{{ $t('quantity') }}</small>
            <div class="d-flex items-center">
              <i class="mdi mdi-cart icon-5x"></i>
              <h5 class="ml-4 mt-3">{{ data.today_total_sale }} {{ $t('bottle') }}</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 grid-margin">
        <div class="card">
          <div class="card-body text-danger">
            <h6 class="card-title text-danger mb-0">{{ $t('today_revenue') }}</h6>
            <small>{{ $t('total') }}</small>
            <div class="d-flex items-center">
              <div class="mdi mdi-format-strikethrough-variant icon-5x"></div>
              <h5 class="ml-4 mt-3">{{ ezy.comma(data.today_total_revenue) }} {{ $t('kyat') }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card today-report-card">
      <div class="card-body">
        <h3 class="card-title">{{ $t('today_scan_report') }}</h3>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>{{ $t('no') }}</th>
                <th>{{ $t('vehicle') }}</th>
                <th class="text-center">{{ $t('total_bottle') }}</th>
                <th class="text-center">{{ $t('first_sale_time') }}</th>
                <th class="text-center">{{ $t('last_sale_time') }}</th>
                <th>{{ $t('first_customer') }}</th>
                <th>{{ $t('last_customer') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="data.today_scan_report.length == 0">
                <td colspan="7">
                  <h4 class="text-center">{{ $t('no_scan_report') }}</h4>
                </td>
              </tr>
              <tr v-else v-for="(d, index) in data.today_scan_report" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ d.username }}</td>
                <td class="text-center">{{ d.total_sale }} {{ $t('bottle') }}</td>
                <td class="text-center">
                  <span class="badge badge-success">{{ ezy.formatTime(d.start_sale_time) }}</span>
                </td>
                <td class="text-center">
                  <span class="badge badge-danger">{{ ezy.formatTime(d.end_sale_time) }}</span>
                </td>
                <td>
                  {{ d.first_customer.full_name }}
                </td>
                <td>
                  {{ d.last_customer.full_name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import moment from 'moment-timezone'
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      show: false,
      customers: [],
      records: [
        {customer: null, total_bottle: 1, user: null, price: 0},
        {customer: null, total_bottle: 1, user: null, price: 0},
      ],
      total_bottle: 1,
      params: {
        from_date: moment().tz('Asia/Yangon').format('YYYY-MM-DD'),
        vehicle: null,
      },
      data: {
        today_scan_report: [],
      },
      inter: null,
      users: [],
      driver: null,
    }
  },
  mounted() {
    this.getData()
    this.getUser()
    this.getCustomers()
    this.inter = setInterval(() => {
      this.getData()
    }, 60000 * 5)
  },
  methods: {
    filterCustomer(driver) {
      this.driver = driver
      this.getCustomers()
    },

    addRecord() {
      this.records.push({customer : null, total_bottle : 1, user: null, price: 0});
    },

    delRecord(index) {
      if(this.records.length > 1) {
        this.records.splice(index, 1);
      }
    },
    
    customerSelected(customer, index) {
      this.records[index].price = customer.bottle_price;
    },

    async getUser() {
      let params = {
        role: 'vehicle',
        page: 1,
        limit: 'all',
        sorts: [
          {
            column: 'username',
            type: 'asc',
          },
        ],
      }
      let res = await axios.get('users', {
        params,
      })
      if (res) {
        this.users = res.data.data
      }
    },

    async submitRecords() {
      let records = this.records.filter((record) => record.customer && record.total_bottle > 0 && record.user);
      if(records.length <= 0) {
        return;
      }
      await axios.post('records', { records })
      this.show = false;
      this.records = [
        {customer: null, total_bottle: 1, user: null, price: 0},
        {customer: null, total_bottle: 1, user: null, price: 0},
      ];
      this.getData();
      $iz.alert('success', this.$t('Successfully submitted'), 'success');
    },

    async getData() {
      let res = await axios.get('dashboards', { params: this.params })
      if (res) {
        this.data = res.data.data
      }
    },

    searchCustomers(keyword) {
      if (!keyword.isTrusted) {
        this.keyword = keyword
        this.getCustomers()
      } else {
        this.getCustomers()
      }
    },

    async getCustomers() {
      if (this.driver != null) {
        var { id } = this.driver
      } else {
        var id = null
      }
      console.log(id)
      let res = await axios.get('all_customers', {
        params: {
          keyword: this.keyword,
          user_id: id,
        },
      })
      this.customers = res.data.data
    },

    customLabel({ full_name, address, phone }) {
      return `${full_name} \n ( ${address} )`
    },
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.inter)
    next()
  },
}
</script>

<style scoped>
.icon-5x {
  font-size: 45px;
  line-height: 55px;
  height: 55px;
}

.mh-custom {
  min-height: 500px;
}

.today-report-card {
  min-height: 500px;
}
</style>
