<template>
  <div class="text-center container mt-5">
    <button class="btn btn-primary no-print mb-3 mr-2" @click="$router.back()">
      <i class="mdi mdi-arrow-left"></i>
      {{$t('back')}}
    </button>
    <button class="btn btn-success no-print mb-3" @click="print">
      <i class="mdi mdi-printer"></i>
      {{$t('print')}}
    </button>
    <div class="row">
      <div class="col-md-6 one-div" v-for="(customer,index) in customers" :key="index">
        <div class="one">
          <div class="logo">
            <span class="abc">ABC</span>
            <span class="middle">Lucky Star</span>
            <small>Purified Drinking Water</small>
          </div>
          <vue-qr :size='350' class="align-center" :text="String(customer.id)" :qid="String(customer.id)"></vue-qr>
          <p class="mt-2 text-bold customer-name">
            {{customer.full_name}}
          </p>
          <p class="address">
            <span class="mm">
              အမှတ် (၄/၁၀၄) ၊ အေးမြသာယာရပ်ကွက် ၊ <br>
              အေးမြသာယာဈေးအနီး ၊
              မြစ်ကြီးနားမြို့ ၊ ကချင်ပြည်နယ်။ </span> <br>
            Ph: 09-4400-041-61(office),
            09-7890-088-86, 09-9548-327-77
          </p>
          <p class="text-left v-no" v-if="vehicle">
            {{vehicle}}
          </p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import VueQr from 'vue-qr'
  export default {
    components: {
      VueQr
    },
    data() {
      return {
        customers: [],
        vehicle: null
      }
    },
    async mounted() {
      this.vehicle = this.$route.params.vehicle;
      let customers = JSON.parse(this.$route.params.customers);
      let res = await axios.post('customers_by_ids', {
        ids: customers
      });
      this.customers = res.data.data;
    },
    methods: {
      print() {
        window.print()
      }
    },
  }
</script>

<style scoped>
  .align-center {
    margin: 0 auto;
  }

  .one {
    min-height: 700px;
    border: 1px dashed black;
    background: white;
    margin-bottom: 85px;
  }

  .one-div:nth-last-child(1) .one {
    margin-bottom: 0px;
  }

  .one-div:nth-last-child(2) .one {
    margin-bottom: 0px;
  }

  .logo {
    padding: 50px 0px;
  }

  .logo .abc {
    position: absolute;
    margin-top: -7px;
    margin-left: 3px;
    font-size: 14px;
    font-family: 'Arizonia', cursive;
  }

  .logo .middle {
    font-size: 30px;
  }

  .logo small {
    display: block;
    font-size: 16px;
  }

  .address {
    margin-top: 55px;
  }

  .customer-name {
    font-size: 18px;
  }
  .v-no {
    position: absolute;
    top: 0;
    color: grey;
    padding-left: 5px;
  }
</style>