<template>
    <div>
        <router-view/>
    </div>
</template>

<script>
import 'bootstrap-vue/dist/bootstrap-vue.css'
export default {
}
</script>